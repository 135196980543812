/* global Component , axios */
class PersonEditModalComponent extends Component {

    static name() {
        return "PersonEditModalComponent";
    }

    static componentName() {
        return "PersonEditModalComponent";
    }

    data() {
        return {
            response: null,
            alertError: null,
            personJobPosition: '',
            personWebAccessGroup: '',
            personCountry: '',
            personCity: '',
            personCenterCost:'',
            currentCustomerPerson:null,
            LiberatorsOptions: {
                "PersonCode": {label: "Person Code", linkto: "Person", pasteField: 'Code', showField: "Name"},
                "PersonName": {label: "Person Name", type: "PersonName", readonly: true},
                "CanLiberate": {label: "Can Liberate", type: "checkbox"},
                "OnlyModified": {label: "Only Modified", type: "checkbox"}
            }
        };
    }

    mounted() {
        return function () {
            this.initialUpdateSelectValues();
        };
    }

    getWatch() {
        return{
            "$store.state.currentCustomerPerson":function (newValue,oldValue){
                //console.log('Watch Modal Person',oldValue,newValue);
                this.currentCustomerPerson = newValue;
            }
        };
    }

    getMethods() {
        return {
            initialUpdateSelectValues:this.initialUpdateSelectValues,
            updateSelectValues: this.updateSelectValues,
            updateCustomerPerson: this.updateCustomerPerson,
            closeModal:this.closeModal,
            liberatorAfterEditRow: this.liberatorAfterEditRow,
            liberatorAfterDeleteRow: this.liberatorAfterDeleteRow,
        };
    }

    liberatorAfterEditRow(row,event,mustAdded) {
        if(event.field == 'PersonCode'){
            if(event.linkToData.length > 0){
                row.fields.PersonName = `${event.linkToData[0]['Name']} ${event.linkToData[0]['LastName']}`;
                row.fields['CanLiberate'] = true;
            }
        }
        if(event.field == 'OnlyModified')
            row.fields['CanLiberate'] = !row.fields['OnlyModified'];

        if(event.field == 'CanLiberate')
            row.fields['OnlyModified'] = !row.fields['CanLiberate'];
        if(mustAdded)
            this.currentCustomerPerson.Liberators.push(row);
    }

    liberatorAfterDeleteRow(row) {
        this.currentCustomerPerson.Liberators = this.currentCustomerPerson.Liberators.filter(x=>x.fields.internalId != row.fields.internalId);
    }

    initialUpdateSelectValues() {
        this.currentCustomerPerson = this.$store.getters.getCurrentCustomerPerson;
        if (this.currentCustomerPerson) {
            /*this.$store.dispatch('updateSelectLinkto', {
                table: 'editPerson_JobPosition',
                value: this.currentCustomerPerson.JobPosition
            });*/
            this.$store.dispatch('updateSelectLinkto', {
                table: 'editPerson_WebAccessGroup',
                value: this.currentCustomerPerson.WebAccessGroup
            });

            this.$store.dispatch('updateSelectLinkto', {
                table: 'editPerson_Country',
                value: this.currentCustomerPerson.Country
            });
            this.$store.dispatch('updateSelectLinkto', {
                table: 'editPerson_City',
                value: this.currentCustomerPerson.City
            });
            this.$store.dispatch('updateSelectLinkto', {
                table: 'editPerson_CenterCost',
                value: this.currentCustomerPerson.DefaultCenterCost
            });
            this.updateSelectValues();
        }
    }

    updateSelectValues(event) {
        //this.personJobPosition = this.$store.getters.getSelectLinkToValue('editPerson_JobPosition');
        this.personWebAccessGroup = this.$store.getters.getSelectLinkToValue('editPerson_WebAccessGroup');
        this.personCountry = this.$store.getters.getSelectLinkToValue('editPerson_Country');
        this.personCity = this.$store.getters.getSelectLinkToValue('editPerson_City');
        this.personCenterCost = this.$store.getters.getSelectLinkToValue('editPerson_CenterCost');
    }

    async updateCustomerPerson() {
        this.alertError = null;
        this.updateSelectValues();
        this.currentCustomerPerson.WebAccessGroup = this.personWebAccessGroup;
        this.currentCustomerPerson.Country = this.personCountry;
        this.currentCustomerPerson.City = this.personCity;
        this.currentCustomerPerson.DefaultCenterCost = this.personCenterCost;
        if (!this.currentCustomerPerson.Name)
            this.alertError = 'Please fill the field Name';
        else if (!this.currentCustomerPerson.LastName)
            this.alertError = 'Please fill the field Last Name';
        else if (!this.currentCustomerPerson.WebUser && this.currentCustomerPerson.WebUserFlag)
            this.alertError = 'Please fill the field User Web';
        else if (!this.currentCustomerPerson.WebPassword && this.currentCustomerPerson.WebUserFlag)
            this.alertError = 'Please enter a password for this Person';

        if (!this.alertError) {
            await axios.post('/ecommerce/api/updateCustomerPerson', this.currentCustomerPerson)
                .then(response => {
                    this.response = response;
                })
                .catch(function (error) {
                    console.warn('Error response in update Person', error);
                });

            if (this.response.data.success) {
                this.$store.dispatch('updateCustomerPerson', this.currentCustomerPerson);
                this.closeModal();
                this.$store.dispatch('showNotificacion', {
                    title: 'Editar Persona',
                    message: 'Datos Personales guardados',
                    type: 'success'
                });
            } else {
                if(this.response.data && this.response.data.errors)
                    this.alertError = this.response.data.errors;
            }
        }
    }

    closeModal() {
        //console.log('Close Modal');
        $("#PersonEditModal").modal('toggle');
        this.alertError = null;
    }

    getTemplate() {
        return`<div key="PersonEditModal"  class="modal fade item-detail-modal" id="PersonEditModal" tabindex="-1" role="dialog" v-if="currentCustomerPerson">
                  <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title">{{tr("Person Code")+': '+currentCustomerPerson.Code }}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="alertError = null">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body">
                                <div class="col-sm-12">
                                      <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" v-if="alertError">
                                           <strong>{{tr(alertError)}}!</strong>
                                           <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertError = null">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                       </div>
                                </div>
                               <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="firstname" class="form-label">{{tr('Name')}}</label>
                                            <input id="personName" v-model="currentCustomerPerson.Name" type="text" class="form-control" autocomplete="none">
                                            <label for="personlastname" class="form-label">{{tr('Last Name')}}</label>
                                            <input id="personLastName" v-model="currentCustomerPerson.LastName" type="text" class="form-control" autocomplete="none">
                                            <!--<label for="personlastname2" class="form-label">{{tr('Mothers Maiden Name')}}</label>
                                            <input id="personlastname2" v-model="currentCustomerPerson.LastName2" type="text" class="form-control">-->
                                            <label for="personCountry" class="form-label">{{tr('Country')}}</label>
                                            <selectLinkToComponent  recordKey="editPerson" :table ="'Country'" :paste ="'Code'" :showField ="'Name'" :selected="currentCustomerPerson.Country" @update-value="updateSelectValues"> </selectLinkToComponent>
                                            <!--<label for="personJobPosition" class="form-label">{{tr('Job Position')}}</label>
                                            <selectLinkToComponent recordKey="editPerson" :table ="'JobPosition'" :paste ="'Code'" :showField ="'Code'" @update-value="updateSelectValues" > </selectLinkToComponent>-->
                                            <label for="CenterCost" class="form-label">{{tr('Default Cost Center')}}</label>
                                            <selectLinkToComponent recordKey="editPerson" :table ="'CenterCost'" :paste ="'Code'" :showField ="'Name'"  :selected="currentCustomerPerson.DefaultCenterCost" @update-value="updateSelectValues" > </selectLinkToComponent>
                                            <div class="form-group row mx-0">
                                               <label class="form-label">{{tr('User Activation')}}: </label>
                                                <div class="custom-control custom-checkbox ml-2">
                                                    <input type="checkbox" class="custom-control-input" id="WebFlagEdit" v-model="currentCustomerPerson.WebUserFlag">
                                                    <label class="custom-control-label" for="WebFlagEdit">{{tr('Enabled')}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                           <label for="WebUser" class="form-label">{{tr('User Web')}}</label>
                                           <input id="WebUser" v-model="currentCustomerPerson.WebUser" type="text" class="form-control">
                                           <label for="WebPassword" class="form-label">{{tr('Password')}}</label>
                                           <input id="WebPassword" v-model="currentCustomerPerson.WebPassword" type="password" class="form-control">
                                           <label for="WebAccessGroup" class="form-label">{{tr('Web Access Group')}}</label>
                                           <selectLinkToComponent  recordKey="editPerson" :table="'WebAccessGroup'" :paste="'Code'" showField="Code" :selected="currentCustomerPerson.WebAccessGroup" :filters="{ CustCode:$store.state.session.fields.CustCode }" @update-value-full="updateSelectValues"> </selectLinkToComponent>
                                           <label for="personMail" class="form-label">{{tr('Mail')}}</label>
                                           <input id="personMail" v-model="currentCustomerPerson.Email" type="text" class="form-control">
                                         </div>
                                    </div>
                                    <div class="col-12">
                                        <recordMatrixComponent rowField="Liberators" :record="{ fields: { ...currentCustomerPerson}}"  :headers="LiberatorsOptions" :afterEditRow="liberatorAfterEditRow" :afterDeleteRow="liberatorAfterDeleteRow"></recordMatrixComponent>
                                    </div>
                                </div>
                           </div>
                          <div class="modal-footer">
                                <button class="btn secondary-btn" @click="updateCustomerPerson()" style="float:right">{{tr('Save')}}</button>
                          </div>
                      </div>
                  </div>
              </div>`;
    }
}
PersonEditModalComponent.registerComponent();